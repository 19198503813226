import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import PublicRoutes from "./components/Routes/PublicRoutes";
import ProtectedRoutes from "./components/Routes/ProtectedRoutes";
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Verification from './components/Auth/Verification';
import SelectPlan from './components/Auth/SelectPlan';
import Dashboard from './components/Dashboard/Dashboard';
import Reminder from './components/Dashboard/Reminder';
import Home from './components/Home/Home';
import About from './components/Home/About';
import Features from './components/Home/Features';
import Pricing from './components/Home/Pricing';
import FAQ from './components/Home/FAQ';
import Contact from './components/Home/Contact';
import CompaniesList from './components/Company/List';
import Account from './components/Account/Account';
import SurveyList from './components/Survey/List';
import Competency from './components/Competency/List';
import Users from './components/User/List';
import Question from './components/Question/List';
import AddSettings from './components/Settings/Survey';
import SettingsSurvey from './components/Settings/List';
import Plan from './components/Plan/List';
import SettingsReport from './components/Settings/Report';
import "./intercepter";
import ForgotPassword from './components/Auth/ForgotPassword';
import Survey from './components/Survey/Survey';
import Report from './components/Report/Report';
import Session from './components/Session/List';
import Competencyy from './components/Competency/Competency';
import Invite from './components/Session/Invite';
import AddQuestion from './components/Question/Add';
import CheckoutSuccess from './components/Auth/CheckoutSuccess';
import CheckoutCancel from './components/Auth/CheckoutCancel';
import PaymentSuccess from './components/Auth/PaymentSuccess';
import SampleSurvey from './components/Survey/SampleSurvey';
import GuestSurvey from './components/Survey/GuestSurvey'
import Blog from './components/Home/Blog';

function App() {
  return (
    <Routes>
      <Route path="/select_plan" element={<SelectPlan />} />
      <Route path="/" element={<PublicRoutes />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/payment_success/:paymentIntentId" element={<PaymentSuccess />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/checkout-success/:sessionId" element={<CheckoutSuccess />}/>
        <Route path="/checkout-cancel" element={<CheckoutCancel />}/>
      </Route>
      
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/companies" element={<CompaniesList />} />
        <Route path="/users" element={<Users />} />       
        <Route path="/plans" element={<Plan />} />   
        <Route path="/profile" element={<Account />} />
        <Route path="/surveys" element={<SurveyList/>}/>   
        <Route path="/competencies" element={<Competency/>}/>   
        <Route path="/questions" element={<Question/>}/>
        <Route path="/survey/:id/:sessionId" element={<Survey/>}/>
        <Route path="/user_survey/:surveyData" element={<GuestSurvey/>}/>
        <Route path="/survey/:id" element={<SampleSurvey/>}/>
        <Route path="/self_evaluation" element={<Survey />}/>
        <Route path="/settings_survey" element={<SettingsSurvey />}/>
        <Route path="/add_setting" element={<AddSettings />}/>
        <Route path="/edit_setting/:settingId" element={<AddSettings/>}/>
        <Route path="/settings_report" element={<SettingsReport />}/>        
        <Route path="/reports" element={<Report />}/>
        <Route path="/sessions" element={<Session />}/>
        {/* <Route path="/competencies-new" element={<Competencyy />}/> */}
        <Route path="/invite-user/:sessionId" element={<Invite />}/>
        <Route path="/reminder/:sessionId" element={<Reminder />}/>
        <Route path="/add-questions/:competencyId" element={<AddQuestion />}/>
      </Route>
    </Routes>
  );
}

export default App;
