import { Document, Page, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer'
Font.register({
   family: 'Poppins',
   fonts: [
      {
         src: `./fonts/Poppins-Regular.ttf`
      },
      {
         src: `./fonts/Poppins-Bold.ttf`,
         fontWeight: 'bold'
      },
      {
         src: `./fonts/Poppins-SemiBold.ttf`,
         fontWeight: 'semibold'
      },
      {
         src: `./fonts/Poppins-Italic.ttf`,
         fontWeight: 'normal',
         fontStyle: 'italic'
      },
      {
         src: `./fonts/Poppins-BoldItalic.ttf`,
         fontWeight: 'bold',
         fontStyle: 'italic'
      }
   ]
})
const styles = StyleSheet.create({
   page: { fontFamily: 'Poppins', backgroundColor: '#f4f5fa' },
   frontPage: { flex: 1, flexDirection: "column", justifyContent: "space-around", alignItems: "center", margin: 30 },
   reportTitle: { fontSize: '30px', color: '#566a7f', marginTop: '10px', fontWeight: 'bold', },
   tableOfContentsHeading: { fontSize: '15px', color: '#566a7f', fontWeight: '600', },
   logo: { width: '260px' },
   subjectName: { fontSize: '19px', fontWeight: 700, color: '#566a7f', marginBottom: 5 },
   orgName: { fontSize: '14px', color: '#566a7f' },
   layout: { margin: "30px", },
   tableOfContents: { height: "600px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", },
   tableOfContentsList: { width: "80%", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "15px", borderBottom: "1px solid #eee", },
   mb2: { marginBottom: "20px" },
   mt2: { marginTop: "20px" },
   introduction: { padding: "2.5% 5% 2.5% 5%" },
   introductionHeading: { color: "#333", marginBottom: "6px", },
   introductionHeadingBorder: { width: "70px", height: "2px", backgroundColor: "#ff5f02", marginBottom: "20px", },
   paraText: { marginTop: 0, marginBottom: "20px", fontSize: "11px", color: "#697a8d", fontWeight: 700, lineHeight: "1.5" },
   paraHeading: { marginTop: "20px", marginBottom: "10px", fontSize: "14px", color: "#566a7f", fontWeight: 700, },
   ratersTable: { width: "100%", display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", padding: "10px 0px", borderBottom: "1px solid #eee", },
   ratersTableContent: { fontSize: "11px", color: "#697a8d", fontWeight: 400, },
   strengthListHeader: { width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "#3fa8c6", padding: "10px 0px", borderRadius: "2px", },
   strengthList: { width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid #eee", padding: "10px 0px", },
   strengthListHeading: { width: "80%", margin: 0, padding: 0, fontSize: "10px", fontWeight: "500", lineHeight: "1.5", color: "#566a7f", },
   strengthListScore: { width: "30px", height: "30px", backgroundColor: "#3fa8c6", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", color: "#fff", fontSize: "15px", fontWeight: "bold", borderRadius: "50%", paddingTop: "7px", },
   SlSelf: { fontSize: "10px", color: "#fff", marginRight: "18px", },
   questionSection: { padding: "5px", },
   questionSectionQue: { marginTop: "10px", marginBottom: "8px", fontSize: "13px", color: "#566a7f", lineHeight: "1.5", fontWeight: 700, },
   questionSectionAns: { paddingBottom: "10px", fontSize: "11px", color: "#697a8d", lineHeight: "1.5", },
   answerSection: { width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", padding: "10px 0", borderBottom: "1px solid #f2f2f2", },
   answerSectionGroupName: { width: "20%", fontSize: "11px", color: "#697a8d", lineHeight: "1.5" },
   answerSectionGroupAnswer: { width: "80%" },
   cardRj: { backgroundColor: "#fff", borderRadius: "5px", padding: "0px 10px", marginBottom: "15px", },
   // cardBody:{paddingLeft:"20px"},
   cardText: { marginTop: 0, marginBottom: "20px", fontSize: "10px", color: "#697a8d", fontWeight: 400, lineHeight: "1.5", padding: "0px 10px", },
   cardHeading: { marginTop: "10px", marginBottom: "10px", fontSize: "12px", color: "#566a7f", fontWeight: 600, padding: "0px 10px", },
   bgLightColor: { backgroundColor: "#fdeded", borderRadius: "4px", padding: "0px 10px", margin: "0px 10px 1px 10px" },
   bgWhiteColor: { backgroundColor: "#fff", borderRadius: "4px", padding: "0px 10px", margin: "0px 10px 1px 10px" },
   bold: { fontWeight: 600 },
   bgLightColorText: { fontSize: "10px", color: "#534f5a", fontWeight: 700, lineHeight: "1.5", padding: "10px 10px 5px 10px", },
   tabledanger: { backgroundColor: '#D87A43', fontSize: 10, color: '#fff', fontWeight: 700 },
   tableorange: { backgroundColor: '#F1A353', fontSize: 10, color: '#fff', fontWeight: 700 },
   tableyellow: { backgroundColor: '#E0DB35', fontSize: 10, color: '#fff', fontWeight: 700 },
   tablelightgreen: { backgroundColor: '#9DB70D', fontSize: 10, color: '#fff', fontWeight: 700 },
   tabledarkgreen: { backgroundColor: '#729847', fontSize: 10, color: '#fff', fontWeight: 700 },
   tableHead: { color: '#333', fontSize: 9, fontWeight: 'bold', padding: '0px 5px' },
   tableBody: { color: 'rgba(58, 53, 65, 0.68)', fontSize: 9, padding: '0px 5px' },
   tableFlex1: { flex: 1 },
   tableFlex3: { flex: 4 },
   tablerating: { display: "flex", justifyContent: "center", alignItems: "center", },
   width50: { width: '50%' },
   width48: { width: '48%' },
   width10: { width: '10%' },
   width90: { width: '90%' },
   width70: { width: '70%' },
   width4: { width: '4%' },
   width3: { width: '2%' },
   width20: { width: '20%' },
   width30: { width: '31%' },
   tablepadding: { padding: '5px' },
   keyMargin: { margin: '10px 5px' },
   keyFont: { fontSize: 9, fontWeight: 'bold' },
   fs12: { fontSize: 12 },
   fs7: { fontSize: 6 },
   fs10: { fontSize: 10 },
   keyPadding: { padding: '10px 5px' },
   keyImage: { height: 'auto', width: '100%' },
   chartImage: { width: '100%', height: 300, objectFit: 'contain', },
   bgColor: { backgroundColor: '#eb5151' },
   bgColor2: { backgroundColor: '#fdeded' },
   bgColortransparent: { backgroundColor: 'transparent' },
   textWhite: { color: '#fff' },
   textblack: { color: '#566a7f' },
   fw400: { fontWeight: 400 },
   fw600: { fontWeight: 'semibold' },
   textAlign: { textAlign: 'center' },
   imgOverview: { width: '40px', marginBottom: '5px' },
   cardOverview: { borderRadius: "5px", padding: "10px 5px", marginBottom: "10px", height: 'auto' },
   headingOverview: { marginBottom: '5px', textAlign: 'center', fontSize: 10, fontWeight: 'semibold' },
   ssBody: { padding: '0px 10px 20px 10px' },
   normText: { fontSize: "9px", color: "#697a8d", fontWeight: 400, padding: "7px", },
   points: { display: "flex", flexDirection: "row", alignItems: 'center' },
   heading: { display: "flex", flexDirection: "row", alignItems: "center", fontWeight: 'bold', fontSize: 14, marginBottom: '12px', color: '#566a7f' },
   pointnumber: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px', height: '50px', width: '35px', color: '#fff', fontWeight: 'bold' },
   KeyTable: { width: "100%", display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", padding: "10px 0px", },
   row: { flexDirection: 'row', alignItems: 'stretch' },
   flexEqual: { flex: 1, },
   summaryChartHeading: {
      fontSize: '10px',
      fontWeight: 700,
      textAlign: 'center',
      color: '#3a3541de'
   },
   KeyTable2: {flexDirection: "row", flexWrap: 'wrap' },
   tableContent: { color: "#999", fontSize: "9px", padding: "0px 5px" }
});

export default function ReportDownloadView({ report }) {
   console.log("report", report)

   return (
      <Document>
         <Page wrap style={styles.page}>
            <View style={styles.frontPage}>
               <Text style={styles.reportTitle}>{report.session_name}</Text>
               <Image style={styles.logo} src={process.env.REACT_APP_BASE_URL + "images/logo.png"} />
               <View>
                  <Text style={styles.subjectName}>{report.user_name}</Text>
               </View>
            </View>
         </Page>
         <Page wrap style={styles.page}>
            <View style={styles.tableOfContents}>
               <Text style={[styles.reportTitle, styles.mb2]}>Table of Contents</Text>
               <View key="toc_overview" style={[styles.tableOfContentsList, styles.mt2]}>
                  <Text style={styles.tableOfContentsHeading}>Overview</Text>
                  <Text style={styles.tableOfContentsHeading}>3</Text>
               </View>
               <View key="toc_key_insights" style={styles.tableOfContentsList}>
                  <Text style={styles.tableOfContentsHeading}>Key Insights</Text>
                  <Text style={styles.tableOfContentsHeading}>4</Text>
               </View>
               <View key="toc_domain_overview" style={styles.tableOfContentsList}>
                  <Text style={styles.tableOfContentsHeading}>Domain Overview</Text>
                  <Text style={styles.tableOfContentsHeading}>5</Text>
               </View>
               <View key="toc_strengths" style={styles.tableOfContentsList}>
                  <Text style={styles.tableOfContentsHeading}>Strengths and Areas for Improvement</Text>
                  <Text style={styles.tableOfContentsHeading}>6</Text>
               </View>
               <View key="toc_variance_review" style={styles.tableOfContentsList}>
                  <Text style={styles.tableOfContentsHeading}>Variance Review</Text>
                  <Text style={styles.tableOfContentsHeading}>7</Text>
               </View>
               <View key="toc_in_depth_analysis" style={styles.tableOfContentsList}>
                  <Text style={styles.tableOfContentsHeading}>In-Depth Analysis</Text>
                  <Text style={styles.tableOfContentsHeading}>8</Text>
               </View>
            </View>
         </Page>
         <Page wrap style={styles.page}>
            <View style={styles.introduction}>
               {/* <Text style={styles.introductionHeading}>Overview</Text> */}
               {/* <Text style={styles.introductionHeadingBorder}></Text> */}
               <View style={styles.heading}>
                  <View style={[styles.pointnumber, styles.bgColor]}>
                     <Text>1</Text>
                  </View>
                  <Text>Overview</Text>
               </View>

               <View style={[styles.cardRj, {marginBottom: 0}]}>
                  <View style={styles.cardBody}>
                     <Text style={[styles.cardHeading]}>Understanding Your Result</Text>
                     <Text style={[styles.cardText, {marginBottom: "10px"}]}>This report provides a comprehensive overview of your personal and professional competencies, highlighting both your strengths and areas that may benefit from further development. By understanding these aspects, you can strategically focus on enhancing your skills and performance.</Text>
                  </View>
               </View>


               <Text style={styles.cardHeading}>Key Benefits</Text>
               <View style={[styles.points, styles.row, {marginBottom: "13px"}]}>
                  <View style={[styles.bgWhiteColor, styles.width30, styles.flexEqual]}>
                     <View style={[styles.tablerating, styles.cardOverview]}>
                        <Image
                           style={styles.imgOverview}
                           src={process.env.REACT_APP_BASE_URL + "images/mirror.png"}
                        />
                        <Text style={[styles.headingOverview, styles.textblack, {marginBottom: 0}]}>Self-Awareness</Text>
                        <Text style={[styles.normText, styles.textAlign]}>
                           Gain a clear understanding of your personal strengths and potential areas for improvement.
                        </Text>
                     </View>
                  </View>
                  <View style={[styles.bgColor, styles.width30, styles.flexEqual]}>
                     <View style={[styles.tablerating, styles.cardOverview]}>
                        <Image
                           style={styles.imgOverview}
                           src={process.env.REACT_APP_BASE_URL + "images/search.png"}
                        />
                        <Text style={[styles.headingOverview, styles.textWhite]}>Comparative Insights</Text>
                        <Text style={[styles.normText, styles.textAlign, styles.textWhite]}>
                           See how your competency levels compare to those of your peers, supervisors, and direct reports.
                        </Text>
                     </View>
                  </View>
                  {/* <View style={[styles.width30, styles.flexEqual]}>
                     <View style={[styles.tablerating, styles.cardOverview, styles.bgColor]}>
                        <Image
                           style={styles.imgOverview}
                           src={process.env.REACT_APP_BASE_URL + "images/search.png"}
                        />
                        <Text style={[styles.textWhite, styles.headingOverview]}>
                           Comparative Insights
                        </Text>
                        <Text style={[styles.normText, styles.textWhite]}>
                           See how your competency levels compare to those of your peers, supervisors, and direct reports.
                        </Text>
                     </View>
                  </View> */}
                  <View style={[styles.bgWhiteColor, styles.width30, styles.flexEqual]}>
                     <View style={[styles.tablerating, styles.cardOverview]}>
                        <Image
                           style={styles.imgOverview}
                           src={process.env.REACT_APP_BASE_URL + "images/chat.png"}
                        />
                        <Text style={[styles.headingOverview, styles.textblack]}>Actionable Feedback</Text>
                        <Text style={[styles.normText, styles.textAlign, styles.textblack]}>
                           Use the detailed insights to create targeted development plans and set achievable goals.
                        </Text>
                     </View>
                  </View>
               </View>

               <View style={styles.cardRj}>
                  <View style={styles.cardBody}>
                     <Text style={[styles.cardHeading]}>Terminology Guide</Text>
                     <Text style={[styles.cardText, {marginBottom: "8px"}]}>To assist you in interpreting your results, we have provided definitions for key terms used throughout the report:</Text>

                     <View style={[{marginBottom: "10px"}]}>
                        <View style={[styles.bgLightColor, styles.points]}>
                           <Text style={[styles.normText]}>
                              <Text >Current:</Text>
                              <Text style={[styles.fw400]}> The average of responses given during the current feedback cycle for each behavior, competency, or domain.</Text>
                           </Text>
                        </View>
                        <View style={[styles.bgWhiteColor, styles.points]}>
                           <Text style={[styles.normText]}>
                              <Text >Previous:</Text>
                              <Text style={[styles.fw400]}> The average of responses given during the current feedback cycle for each behavior, competency, or domain.</Text>
                           </Text>
                        </View>
                        <View style={[styles.bgLightColor, styles.points]}>
                           <Text style={[styles.normText]}>
                              <Text >Rater Group:</Text>
                              <Text style={[styles.fw400]}> A group of raters who are at the same professional level relative to you (e.g., PEERS, SUPERVISORS, DIRECT REPORTS).</Text>
                           </Text>
                        </View>
                        <View style={[styles.bgWhiteColor, styles.points]}>
                           <Text style={[styles.normText]}>
                              <Text >Previous:</Text>
                              <Text style={[styles.fw400]}> The average of responses given during the previous feedback cycle for each behavior, competency, or domain.</Text>
                           </Text>
                        </View>
                        <View style={[styles.bgLightColor, styles.points]}>
                           <Text style={[styles.normText]}>
                              <Text >GAP:</Text>
                              <Text style={[styles.fw400]}> The difference between the scores of two rater groups or between two time periods.</Text>
                           </Text>
                        </View>
                        <View style={[styles.bgWhiteColor, styles.points]}>
                           <Text style={[styles.normText]}>
                              <Text >Total:</Text>
                              <Text style={[styles.fw400]}> The average of all responses given by all raters (excluding self) for each behavior, competency, or domain.</Text>
                           </Text>
                        </View>
                        <View style={[styles.bgLightColor, styles.points]}>
                           <Text style={[styles.normText]}>
                              <Text >Self:</Text>
                              <Text style={[styles.fw400]}> How you rated yourself for each behavior or the average of all your scores that pertain to a specific competency or domain.</Text>
                           </Text>
                        </View>
                     </View>

                     <Text style={[styles.cardText, {marginBottom: "10px", marginTop: "0px"}]}>By familiarizing yourself with these terms, you will be better equipped to analyze and understand the feedback provided in this report, enabling you to make informed decisions about your personal and professional development.</Text>
                  </View>
               </View>
            </View>
         </Page>
         <Page wrap style={styles.page}>
            <View style={styles.introduction}>
               <View key="heading_key_insights" style={styles.heading}>
                  <View style={[styles.pointnumber, styles.bgColor]}>
                     <Text>2</Text>
                  </View>
                  <Text>Key Insights</Text>
               </View>

               <View key="HeadingStrongestWeakest" style={[styles.KeyTable]}>
                  <View key="HeadingStrongest" style={[styles.cardRj, styles.width48, { marginBottom: 0 }]}>
                     <View style={[styles.tablerating]}>
                        <Text style={[styles.keyPadding, styles.keyFont, styles.textblack]}>Strongest Competency</Text>
                     </View>
                  </View>
                  <View key="HeadingStrongestWeakestSpace" style={styles.width4}>
                  </View>
                  <View key="HeadingWeakest" style={[styles.cardRj, styles.width48, { marginBottom: 0 }]}>
                     <View style={[styles.tablerating]}>
                        <Text style={[styles.keyPadding, styles.keyFont, styles.textblack]}>Weakest Competency</Text>
                     </View>
                  </View>
               </View>
               <View key="ChartStrongestWeakest" style={[styles.KeyTable]}>
                  <View key="ChartStrongest" style={styles.width48}>
                     <Image style={styles.keyImage} src={report.strongestImageUrl} />
                  </View>
                  <View key="ChartStrongestWeakestSpace" style={styles.width4}>

                  </View>
                  <View key="ChartWeakest" style={styles.width48}>
                     <Image style={styles.keyImage} src={report.weakestImageUrl} />
                  </View>
               </View>

               <View key="TOPTHREERATEDQUESTIONS">
                  <View key="HeadingTOPTHREERATEDQUESTIONS" style={[styles.ratersTable, styles.bgColor]}>
                     <Text style={[styles.tableHead, styles.width90, styles.textWhite, styles.keyFont]}>TOP THREE RATED QUESTIONS</Text>
                     <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keyFont]}>SCORE</Text>
                  </View>
                  {report.executive_summary && report.executive_summary.top_question && report.executive_summary.top_question.length > 0 ? (
                     report.executive_summary.top_question.map((excutivedata, index) => (
                        <View key={"HeadingTOPTHREERATEDQUESTIONS"+index} style={styles.ratersTable}>
                           <Text style={[styles.tableBody, styles.width90]}>{excutivedata.question.name}</Text>
                           <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{excutivedata.avg_surveyAnswer}</Text>
                        </View>
                     ))
                  ) : ''}
               </View>

               <View>
                  <View style={[styles.ratersTable, styles.bgColor]}>
                     <Text style={[styles.tableHead, styles.width70, styles.textWhite, styles.keyFont]}>TOP THREE LARGEST RATING GAPS</Text>
                     <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keyFont]}>OTHERS</Text>
                     <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keyFont]}>SELF</Text>
                     <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keyFont]}>GAP</Text>
                  </View>
                  {report.executive_summary && report.executive_summary.rating_gap && report.executive_summary.rating_gap.length > 0 ? (
                     report.executive_summary.rating_gap.map((excutivedata, index) => (
                        <View style={styles.ratersTable}>
                           <Text style={[styles.tableBody, styles.width70]}>{excutivedata.domain}</Text>
                           <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{excutivedata.other_rate}</Text>
                           <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{excutivedata.self_rate}</Text>
                           <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{excutivedata.gap}</Text>
                        </View>
                     ))
                  ) : <></>}
               </View>

               <View key="SUMMARYOFSCORES">
                  <View key="HeadingSUMMARYOFSCORES" style={[styles.ratersTable, styles.bgColor]}>
                     <Text style={[styles.tableHead, styles.width90, styles.textWhite, styles.keyFont]}>SUMMARY OF SCORES</Text>
                     <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keyFont]}>SCORE</Text>
                  </View>
                  <View key="TotalSUMMARYOFSCORES" style={styles.ratersTable}>
                     <Text style={[styles.tableBody, styles.width90]}>Total Score</Text>
                     <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{report.executive_summary && report.executive_summary.overallScore ? report.executive_summary.overallScore.score : ''}</Text>
                  </View>
               </View>
            </View>
         </Page>
         <Page wrap style={styles.page}>
            <View style={styles.introduction}>
               <View key="HeadingDomainOverview" style={styles.heading}>
                  <View style={[styles.pointnumber, styles.bgColor]}>
                     <Text>3</Text>
                  </View>
                  <Text>Domain Overview</Text>
               </View>
               {/* <Text style={styles.introductionHeading}>Domain Overview</Text>
               <Text style={styles.introductionHeadingBorder}></Text> */}
               <View key="DataDomainOverview" style={[styles.KeyTable2]}>
                  {report.domain_summary && report.domain_summary.length > 0 ? (
                     report.domain_summary.map((summary, index) => (
                        <View key={index} style={[{ flexBasis: '33%', padding: 5, marginBottom: 15 }]}>
                           <Text style={styles.summaryChartHeading}>{summary.domain}</Text>
                           <View>
                              <Image style={styles.keyImage} src={summary.chart_img} />
                           </View>
                        </View>
                     ))
                  ) : <></>}
               </View>
               <View key="DomainOverviewDescription" style={[styles.cardRj]}>
                  <View style={[styles.cardBody]}>
                     <Text style={styles.cardHeading}>Description</Text>
                     <Text style={styles.cardText}>These charts provide a visual summary of each domain for self, others and company average.</Text>
                  </View>
               </View>
            </View>
         </Page>
         <Page wrap style={styles.page}>
            <View style={styles.introduction}>
               <View style={styles.heading}>
                  <View style={[styles.pointnumber, styles.bgColor]}>
                     <Text>4</Text>
                  </View>
                  <Text>Strengths and Areas for Improvement</Text>
               </View>
               {/* <Text style={styles.introductionHeading}>Strengths and Areas for Improvement</Text>
               <Text style={styles.introductionHeadingBorder}></Text> */}

               <View style={[styles.cardRj]}>
                  <View style={[styles.cardBody]}>
                     <Text style={styles.cardHeading}>Five Heighest Score</Text>
                     <View style={[styles.ssBody]}>
                        <View style={[styles.ratersTable, styles.bgColor]}>
                           <Text style={[styles.tableHead, styles.width20, styles.textWhite, styles.keythFont]}>Domain</Text>
                           <Text style={[styles.tableHead, styles.width90, styles.textWhite, styles.keythFont]}>Question</Text>
                           <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keythFont]}>Total</Text>
                        </View>
                        {report.high_low_score && report.high_low_score.heighest_score && report.high_low_score.heighest_score.length > 0 ? (
                           report.high_low_score.heighest_score.map((highlowdata, index) => (
                              <View style={[styles.ratersTable, (index + 1) % 2 === 0 ? styles.bgColor2 : {}]}>
                                 <Text style={[styles.tableBody, styles.width20]}>{highlowdata.question.competency.name}</Text>
                                 <Text style={[styles.tableBody, styles.width90]}>{highlowdata.question.name}</Text>
                                 <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{highlowdata.avg_surveyAnswer}</Text>
                              </View>
                           ))
                        ) : <></>}

                     </View>
                  </View>
               </View>

               <View style={[styles.cardRj]}>
                  <View style={[styles.cardBody]}>
                     <Text style={styles.cardHeading}>Five Lowest Score</Text>
                     <View style={[styles.ssBody]}>
                        <View style={[styles.ratersTable, styles.bgColor]}>
                           <Text style={[styles.tableHead, styles.width20, styles.textWhite, styles.keythFont]}>Domain</Text>
                           <Text style={[styles.tableHead, styles.width90, styles.textWhite, styles.keythFont]}>Question</Text>
                           <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keythFont]}>Total</Text>
                        </View>
                        {report.high_low_score && report.high_low_score.lowest_score && report.high_low_score.lowest_score.length > 0 ? (
                           report.high_low_score.lowest_score.map((highlowdata, index) => (
                              <View style={[styles.ratersTable, (index + 1) % 2 === 0 ? styles.bgColor2 : {}]}>
                                 <Text style={[styles.tableBody, styles.width20]}>{highlowdata.question.competency.name}</Text>
                                 <Text style={[styles.tableBody, styles.width90]}>{highlowdata.question.name}</Text>
                                 <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{highlowdata.avg_surveyAnswer}</Text>
                              </View>
                           ))
                        ) : <></>}
                     </View>
                  </View>
               </View>
            </View>
         </Page>
         <Page wrap style={styles.page}>
            <View style={styles.introduction}>
               <View style={styles.heading}>
                  <View style={[styles.pointnumber, styles.bgColor]}>
                     <Text>5</Text>
                  </View>
                  <Text>Variance Review</Text>
               </View>
               {/* <Text style={styles.introductionHeading}>Variance Review</Text>
               <Text style={styles.introductionHeadingBorder}></Text> */}

               <View style={[styles.cardRj]}>
                  <View style={[styles.cardBody]}>
                     <Text style={styles.cardHeading}>Positive Gaps</Text>
                     <View style={[styles.ssBody]}>
                        <View style={[styles.ratersTable, styles.bgColor]}>
                           <Text style={[styles.tableHead, styles.width20, styles.textWhite, styles.keythFont]}>Domain</Text>
                           <Text style={[styles.tableHead, styles.width50, styles.textWhite, styles.keythFont]}>Question</Text>
                           <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keythFont]}>Others</Text>
                           <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keythFont]}>Self</Text>
                           <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keythFont]}>Gap</Text>
                        </View>
                        {report.positive_negative_gap && report.positive_negative_gap.possitive && report.positive_negative_gap.possitive.length > 0 ? (
                           report.positive_negative_gap.possitive.map((ratinggapdata, index) => (
                              <View style={[styles.ratersTable, (index + 1) % 2 === 0 ? styles.bgColor2 : {}]}>
                                 <Text style={[styles.tableBody, styles.width20]}>{ratinggapdata.domain}</Text>
                                 <Text style={[styles.tableBody, styles.width50]}>{ratinggapdata.question}</Text>
                                 <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{ratinggapdata.other_rate}</Text>
                                 <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{ratinggapdata.self_rate}</Text>
                                 <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{ratinggapdata.gap}</Text>
                              </View>
                           ))
                        ) : <></>}
                     </View>
                  </View>
               </View>

               <View break style={[styles.cardRj, { marginTop: '5%' }]}>
                  <View style={[styles.cardBody]}>
                     <Text style={styles.cardHeading}>Negative Gaps</Text>
                     <View style={[styles.ssBody]}>
                        <View style={[styles.ratersTable, styles.bgColor]}>
                           <Text style={[styles.tableHead, styles.width20, styles.textWhite, styles.keythFont]}>Domain</Text>
                           <Text style={[styles.tableHead, styles.width50, styles.textWhite, styles.keythFont]}>Question</Text>
                           <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keythFont]}>Others</Text>
                           <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keythFont]}>Self</Text>
                           <Text style={[styles.width10, styles.tableHead, styles.textWhite, styles.keythFont]}>Gap</Text>
                        </View>
                        {report.positive_negative_gap && report.positive_negative_gap.negative && report.positive_negative_gap.negative.length > 0 ? (
                           report.positive_negative_gap.negative.map((ratinggapdata, index) => (
                              <View
                                 break={(index + 1) % 16 === 0 ? true : false}
                                 style={[styles.ratersTable, (index + 1) % 2 === 0 ? styles.bgColor2 : {}, (index + 1) % 16 === 0 ? { marginTop: '5%' } : {}]}
                              >
                                 <Text style={[styles.tableBody, styles.width20]}>{ratinggapdata.domain}</Text>
                                 <Text style={[styles.tableBody, styles.width50]}>{ratinggapdata.question}</Text>
                                 <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{ratinggapdata.other_rate}</Text>
                                 <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{ratinggapdata.self_rate}</Text>
                                 <Text style={[styles.width10, styles.tableBody, styles.fw600]}>{ratinggapdata.gap}</Text>
                              </View>
                           ))
                        ) : <></>}

                     </View>
                  </View>
               </View>
            </View>
         </Page>
         <Page wrap style={styles.page}>
            <View style={styles.introduction}>
               <View style={styles.heading}>
                  <View style={[styles.pointnumber, styles.bgColor]}>
                     <Text>6</Text>
                  </View>
                  <Text>In-Depth Analysis</Text>
               </View>
               {/* <Text style={styles.introductionHeading}>In-Depth Analysis</Text>
               <Text style={styles.introductionHeadingBorder}></Text> */}

               {/* <View style={styles.cardRj}>
                  <View style={styles.cardBody}>
                     <View style={styles.ratersTable}>
                        <Text style={[styles.width50, styles.tableHead, styles.textblack]}>QUESTION</Text>
                        <Text style={[styles.width50, styles.tableHead, styles.textblack]}></Text>
                     </View>
                     <View style={styles.ratersTable}>
                        <Text style={[styles.width50, styles.tableBody]}>Fills Timesheet on time, every time, in the right projects</Text>
                        <Text style={[styles.width50, styles.tableBody]}>Fills Timesheet on time, every time, in the right projects</Text>
                     </View>
                  </View>
               </View> */}
               {report.detailed_results && report.detailed_results.length > 0 ? report.detailed_results.map((competency, index) => (
                  <View
                     break={(index + 1) === 2 || (index + 1) === 3 || (index + 1) === 5 || (index + 1) === 7 || (index + 1) === 9 || (index + 1) === 11 || (index + 1) === 13 || (index + 1) === 15 ? true : false}
                     style={(index + 1) === 2 || (index + 1) === 3 || (index + 1) === 5 || (index + 1) === 7 || (index + 1) === 9 || (index + 1) === 11 || (index + 1) === 13 || (index + 1) === 15 ? { marginTop: '5%' } : {}}
                  >
                     <Text style={styles.paraText}>{competency.name}</Text>
                     <View style={styles.cardRj}>
                        <View style={styles.cardBody}>
                           <View style={styles.ratersTable}>
                              <Text style={[styles.width50, styles.tableHead, styles.fs7, styles.textblack]}>QUESTION</Text>
                              {
                                 competency.name.includes("Open Ended") ?
                                    <Text style={[styles.width50, styles.tableHead, styles.fs7, styles.textblack]}>Answer</Text>
                                    :
                                    <>
                                       <Text style={[styles.width10, styles.tableHead, styles.fs7, styles.textblack]}>SELF</Text>
                                       <Text style={[styles.width10, styles.tableHead, styles.fs7, styles.textblack]}>MANAGER</Text>
                                       <Text style={[styles.width10, styles.tableHead, styles.fs7, styles.textblack]}>DIRECT REPORTS</Text>
                                       <Text style={[styles.width10, styles.tableHead, styles.fs7, styles.textblack]}>PEERS</Text>
                                       <Text style={[styles.width10, styles.tableHead, styles.fs7, styles.textblack]}>OTHERS</Text>
                                    </>
                              }

                           </View>
                           {competency.questions.map((question, i) => (
                              question.questionTypeId === 1 ?
                                 <View style={styles.ratersTable}>
                                    <Text style={[styles.width50, styles.tableBody]}>{question.name}</Text>
                                    <View style={[styles.width10, styles.tablerating,
                                    question.surveyAnsers.self > 0 && question.surveyAnsers.self <= 1 ? styles.tabledanger :
                                       question.surveyAnsers.self > 1 && question.surveyAnsers.self <= 2 ? styles.tableorange :
                                          question.surveyAnsers.self > 2 && question.surveyAnsers.self <= 4 ? styles.tableyellow :
                                             question.surveyAnsers.self > 4 && question.surveyAnsers.self <= 5.5 ? styles.tablelightgreen :
                                                question.surveyAnsers.self > 5.5 && question.surveyAnsers.self <= 7 ? styles.tabledarkgreen : {}
                                    ]}>
                                       <Text style={styles.tablepadding}>{question.surveyAnsers?.self}</Text>
                                    </View>
                                    <View style={[styles.width10, styles.tablerating,
                                    question.surveyAnsers.manager > 0 && question.surveyAnsers.manager <= 1 ? styles.tabledanger :
                                       question.surveyAnsers.manager > 1 && question.surveyAnsers.manager <= 2 ? styles.tableorange :
                                          question.surveyAnsers.manager > 2 && question.surveyAnsers.manager <= 4 ? styles.tableyellow :
                                             question.surveyAnsers.manager > 4 && question.surveyAnsers.manager <= 5.5 ? styles.tablelightgreen :
                                                question.surveyAnsers.manager > 5.5 && question.surveyAnsers.manager <= 7 ? styles.tabledarkgreen : {}
                                    ]}>
                                       <Text style={styles.tablepadding}>{question.surveyAnsers?.manager}</Text>
                                    </View>
                                    <View style={[styles.width10, styles.tablerating,
                                    question.surveyAnsers.directReportees > 0 && question.surveyAnsers.directReportees <= 1 ? styles.tabledanger :
                                       question.surveyAnsers.directReportees > 1 && question.surveyAnsers.directReportees <= 2 ? styles.tableorange :
                                          question.surveyAnsers.directReportees > 2 && question.surveyAnsers.directReportees <= 4 ? styles.tableyellow :
                                             question.surveyAnsers.directReportees > 4 && question.surveyAnsers.directReportees <= 5.5 ? styles.tablelightgreen :
                                                question.surveyAnsers.directReportees > 5.5 && question.surveyAnsers.directReportees <= 7 ? styles.tabledarkgreen : {}
                                    ]}>
                                       <Text style={styles.tablepadding}>{question.surveyAnsers?.directReportees}</Text>
                                    </View>
                                    <View style={[styles.width10, styles.tablerating,
                                    question.surveyAnsers.peers > 0 && question.surveyAnsers.peers <= 1 ? styles.tabledanger :
                                       question.surveyAnsers.peers > 1 && question.surveyAnsers.peers <= 2 ? styles.tableorange :
                                          question.surveyAnsers.peers > 2 && question.surveyAnsers.peers <= 4 ? styles.tableyellow :
                                             question.surveyAnsers.peers > 4 && question.surveyAnsers.peers <= 5.5 ? styles.tablelightgreen :
                                                question.surveyAnsers.peers > 5.5 && question.surveyAnsers.peers <= 7 ? styles.tabledarkgreen : {}
                                    ]}>
                                       <Text style={styles.tablepadding}>{question.surveyAnsers?.peers}</Text>
                                    </View>
                                    <View style={[styles.width10, styles.tablerating,
                                    question.surveyAnsers.others > 0 && question.surveyAnsers.others <= 1 ? styles.tabledanger :
                                       question.surveyAnsers.others > 1 && question.surveyAnsers.others <= 2 ? styles.tableorange :
                                          question.surveyAnsers.others > 2 && question.surveyAnsers.others <= 4 ? styles.tableyellow :
                                             question.surveyAnsers.others > 4 && question.surveyAnsers.others <= 5.5 ? styles.tablelightgreen :
                                                question.surveyAnsers.others > 5.5 && question.surveyAnsers.others <= 7 ? styles.tabledarkgreen : {}
                                    ]}>
                                       <Text style={styles.tablepadding}>{question.surveyAnsers?.others}</Text>
                                    </View>
                                 </View>
                                 :
                                 <View style={styles.ratersTable}>
                                    <Text style={[styles.width50, styles.tableBody]}>{question.name}</Text>
                                    <View style={[styles.width50, styles.tablerating]}>                                       
                                       {
                                          question.surveyAnsers.map(answer => ( <Text style={[styles.tablepadding, styles.tableContent]}>{answer}</Text> ))
                                       }                                       
                                    </View>
                                 </View>
                           ))}

                        </View>
                     </View>
                  </View>
               )) : <></>}


            </View>
         </Page>
      </Document>
   )
}