import Footer from "./components/Footer";
import Header from "./components/Header";
import * as React from 'react';

export default function Blog() {
    return (
        <>
            <Header/>
            <section className="py-5 ">
                <div className="container">
                    {/* <div className="comming-soon"> */}
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 text-center">
                                <h2 className="mb-3">Comming Soon...</h2>
                                <p className="text-muted fs-14 mb-3">We will be celebrating the launch of our new site very soon!</p>
                                <img className="img-fluid w-100 mb-3" src="./images/under-construction.svg" />
                                <a href="/" className="btn btn-theme">Back to Home</a>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </section>

            <Footer/>            
        </>
        
    )     
}