export const Footer = () => {
    return (
        <footer className="map-bg bg-light footer-bg">
            <div className="container py-3 border-bottom z-index-20 position-relative">
                <div className="row pt-5 gy-4">
                    <div className="col-lg-3 col-md-6"><img className="mb-3" src="./images/logo.png" alt="" width="150" />
                        <p className="mb-0 fs-13 fw-500 text-muted">At PeopleAssay, we're on a mission to revolutionize the way organizations approach feedback and development. Meet the passionate team driving innovation and empowering growth every step of the way.</p>
                        {/* <ul className="list-unstyled text-muted"> */}
                        {/* <li><a className="reset-anchor fs-13 fw-500" href="mailto:info@peopleassay.com">info@peopleassay.com</a></li> */}
                        {/* <li><a className="reset-anchor fs-13 fw-500" href="tel:+001234567890">+ 00 123 456 7890</a></li> */}
                        {/* </ul> */}
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4>Useful Links</h4>
                        <ul className="list-unstyled text-muted mb-0">
                            <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="/">Home</a></li>
                            <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="/about">About</a></li>
                            <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="/features">Features</a></li>
                            <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="/pricing">Pricing</a></li>
                            <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4>Quick Links</h4>
                        <ul className="list-unstyled text-muted mb-0">
                            <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="faq">FAQ</a></li>
                            <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="blog">Blog</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 widget-info">
                        <h4>Address</h4>
                        <ul>
                            <li>
                                <div class="fs-13 fw-500 text-muted">
                                    <span className="fw-bold">India Office - </span>
                                    707, Siddhartha Building,
                                    96 Nehru Place,
                                    New Delhi - 110019, India
                                </div>
                            </li>
                            <li>
                                <div class="fs-13 fw-500 text-muted">
                                    <span className="fw-bold">US Office - </span>
                                    120 Old Camplain Road,
                                    Suite 2K, Hillsborough,
                                    NJ 08844
                                </div>
                            </li>
                            <li><a className="reset-anchor fs-13 fw-500 text-muted" href="mailto:info@peopleassay.com">info@peopleassay.com</a></li>
                            {/* <li><a className="reset-anchor fs-13 fw-500 text-muted" href="tel:+001234567890">+ 00 123 456 7890</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container py-4 z-index-20 position-relative">
                <div className="row text-center">
                    <div className="col-lg-6 p-lg-0 text-lg-start">
                        <p className="text-muted fs-13 fw-500 mb-0">© 2023 People Assay, All Rights Reserved.</p>
                    </div>
                    <div className="col-lg-6 p-lg-0 text-lg-end">
                        <p className="text-muted fs-13 fw-500 mb-0">designed by <a className="text-theme reset-anchor" href="https://actiknow.com/" target="_blank">Actiknow Consulting Pvt. Ltd.</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;