import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import React from "react";
export default function Contact() {
    return (
        <>
            <Header/>
            <section class="bg_cover">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="page-banner-cont">
                                <h2 className="text-white">Contact Us</h2>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            Contact Us
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-9 mx-auto text-center mb-4">
                            <p className="h6 text-theme text-uppercase">Get in Touch</p>
                            <h2 className="mb-3">Connect with Us</h2>
                            <p className="text-muted fs-14 mb-3">Have questions or want to learn more about PeopleAssay? Get in touch with us today. We're here to help you unlock your organization's full potential.</p>
                        </div>
                        <div className="col-md-6">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.1863862156288!2d77.02287397429187!3d28.594184785823188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b11d515e155%3A0x599a16899e48a88e!2sActiknow%20Consulting%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1714028274719!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-md-6">
                            <p className="h6 text-theme text-uppercase">Ready to Get Started?</p>
                            <h2 className="mb-3">Schedule Your Personalized Demo</h2>
                            <p className="text-muted fs-14 mb-3">Schedule a personalized demo with one of our experts and see how PeopleAssay can transform your feedback processes. Get started today and unlock your organization's full potential.</p>
                                <div className="row">
                                    <div className="col-lg-6 mx-auto">
                                        <Link href="#" className="btn btn-theme w-100 py-3">Get Started!</Link>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>            
        </>
        
    )     
}