import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';

const StrongestComponent = ({domain, strongestCaptureChart}) => {
  const chartRef = useRef(null); // Create a ref for the chart instance

  const options = {
    chart: {
      background: '#f4f5fa',
      id: 'basic-bar',
      events: {
        mounted: () => {
          // Ensure that the chart is fully mounted before capturing
          if (chartRef.current && chartRef.current.chart) {
            const chartInstance = chartRef.current.chart; 
            strongestCaptureChart(chartInstance); // Call the parent's onCapture function to capture the image
          }
        },
      },
    },
    colors: ["#f5a8a8"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
          background: "#eb5151"
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: "#fff",
            fontSize: "18px",
            fontWeight: '600',
          },
          value: {
            color: "#fff",
            fontSize: "30px",
            fontWeight: '900',
            show: true,
            formatter: function (val, opts) {
              return (val*7)/100
            },
          }
        }
      },
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     type: "vertical",
    //     gradientToColors: ["#eb5151"],
    //     stops: [0, 100]
    //   }
    // },
    stroke: {
      lineCap: "round"
    },
    
    labels: [domain.name]
  };

  const series = [(domain.score*100)/7];

  useEffect(() => {
    if (chartRef.current) {
      // Force a redraw to ensure bars are visible for radial bar chart
      chartRef.current.chart.updateOptions(options);
      
    }
  }, [options]);

  return (
    <div>
      <Chart options={options} series={series} type="radialBar" height="250" ref={chartRef}/>
    </div>
  );
};

export default StrongestComponent;