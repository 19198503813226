import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import CardNavigationCenter from './components/CardNavigationCenter'
import axios from 'axios'
import Button from '@mui/material/Button'
import { useEffect, useRef, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'
import ReportDownloadView from "./ReportDownloadView";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';

export default function Report() {
    const authUserObj = localStorage.getItem('authUser');
    const authUser = JSON.parse(authUserObj);
    const [sessions, setSessions] = useState([]);
    const [currentSessionId, setCurrentSessionId] = useState('');
    const [selfEvaluations, setSelfEvaluations] = useState([]);
    const [currentUserId, setCurrentUserId] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [executiveSummary, setExecutiveSummary] = useState([]);
    const [domainSummary, setDomainSummary] = useState([]);
    const [highLowScore, setHighLowScore] = useState([]);
    const [positiveNegativeGap, setPositiveNegativeGap] = useState([]);
    const [detailedResults, setDetailedResults] = useState([]);

    const [strongestImageUrl, setStrongestImageUrl] = useState(null);
    const [weakestImageUrl, setWeakestImageUrl] = useState(null);

    const strongestCaptureChart = async (chartInstance) => {
        const imageUrl = await chartInstance.dataURI(); // Capture the image as a base64 string
        setStrongestImageUrl(imageUrl.imgURI); // Set the captured image URL for the PDF
    };

    const weakestCaptureChart = async (chartInstance) => {
        const imageUrl = await chartInstance.dataURI(); // Capture the image as a base64 string
        setWeakestImageUrl(imageUrl.imgURI); // Set the captured image URL for the PDF
    };

    const domainCaptureChart = async (chartInstance, index) => {
        const imageUrl = await chartInstance.dataURI(); // Capture the image as a base64 string
        domainSummary[index].chart_img = imageUrl.imgURI; // Set the captured image URL for the PDF

        // chartInstance.dataURI({ background: 'transparent' }).then((uri) => {
        //     domainSummary[index].chart_img  = uri.imgURI; // Set the image URI (base64 data)
        //   });
    };

    useEffect(() => {
        getSessions();
    }, [])

    const getSessions = async () => {
        await axios.get(`/api/sessions?status=0`).then(function (response) {
            let result = response.data;
            if (result.success) {                
                setSessions(result.data);
            } else {
                // setIsError(true);
            }
        });
    };
    const handleSessionChange = async (sessionId) => {
        setCurrentUserId('')
        setCurrentSessionId(sessionId)
        await getSessionsUsers(sessionId);
    }
    const getSessionsUsers = async (currentSessionId) => {
        await axios.get(`/api/self_evaluations?sessionId=${currentSessionId}`).then(function (response) {
            let result = response.data;
            if (result.success) {              
                setSelfEvaluations(result.data);
            } else {          
                // setIsError(true);
            }
        });
    };
    const handleUserChange = (userId) => {
        console.log("userId",userId)    
        setCurrentUserId(parseInt(userId))        
    }

    const handleDownload = async () => {
        // setIsReportLoading(true)
        try {            
            const response = await axios.get(`/api/getReport?userId=${currentUserId}&sessionId=${currentSessionId}`);
            const result = response.data;
            if (result.success) {
                let reportData = {
                    session_name: result.data.session.name,
                    user_name: result.data.user.name,
                    executive_summary: executiveSummary,
                    domain_summary: domainSummary,
                    high_low_score: highLowScore,
                    positive_negative_gap: positiveNegativeGap,
                    detailed_results: detailedResults,
                    strongestImageUrl: strongestImageUrl,
                    weakestImageUrl: weakestImageUrl
                }
                
                const blob = await pdf((
                    <ReportDownloadView report={reportData} />
                )).toBlob();
                saveAs(blob, result.data.user.name + ".pdf");
            }
            // setIsReportLoading(false)
        }
        catch (error) {
            console.error('Error fetching get report:', error);
            // setIsReportLoading(false)
        }
    }

    useEffect(() => {
        if(currentSessionId > 0 && currentUserId > 0){
            setIsLoading(true)
            getExecutiveSummaryList();
            getDomainSummary();
            getHighandLowScoreList();
            getPositiveandNegativeGapList();
            getDetailedResults();
            // getSessionSettings();
        }
    }, [currentSessionId, currentUserId]);
    
    const getExecutiveSummaryList = async () => {
        try {
          let conditions = `?session_id=${currentSessionId}`;
          conditions += currentUserId ? `&userId=${currentUserId}` : '';
          const response = await axios.get(`/api/excutive_summary${conditions}`);
          const result = response.data;
          if (result.success) {
            setExecutiveSummary(result.data);
            // console.log('result', result.data);
          }
        } catch (error) {
          console.error('Error fetching executive summary:', error);
        }
    }
    
    const getDomainSummary = async () => {
        try {
          let conditions = `?session_id=${currentSessionId}`;
          conditions += currentUserId ? `&userId=${currentUserId}` : '';
          const response = await axios.get(`/api/summary_domain${conditions}`);
          const result = response.data;
          if (result.success) {
            setDomainSummary(result.data);
          }
        } catch (error) {
          console.error('Error fetching domain summary:', error);
        }
    }
    
    const getHighandLowScoreList = async () => {
        try {
          let conditions = `?session_id=${currentSessionId}`;
          conditions += currentUserId ? `&userId=${currentUserId}` : '';
          const response = await axios.get(`/api/high_low_score${conditions}`);
          const result = response.data;
          if (result.success) {
            setHighLowScore(result.data);
            // console.log('result', result.data);
          }
        } catch (error) {
          console.error('Error fetching High and Low Score:', error);
        }
    }
    
    const getPositiveandNegativeGapList = async () => {
        try {
          let conditions = `?session_id=${currentSessionId}`;
          conditions += currentUserId ? `&userId=${currentUserId}` : '';
          const response = await axios.get(`/api/possitive_negative_gap${conditions}`);
          const result = response.data;
          if (result.success) {
            setPositiveNegativeGap(result.data);
            // console.log('result', result.data);
          }
        } catch (error) {
          console.error('Error fetching Positive and Negative Gap:', error);
        }
    }
    
    const getDetailedResults = async () => {
        try {
          let conditions = `?session_id=${currentSessionId}`;
          conditions += currentUserId ? `&userId=${currentUserId}` : '';
          const response = await axios.get(`/api/detailed_result${conditions}`);
          const result = response.data;
          if (result.success) {
            setDetailedResults(result.data);
            setIsLoading(false)
          }
        } catch (error) {
          console.error('Error fetching Detailed Results:', error);
        }
    }

    // const getSessionSettings = async () => {
    //     try {
    //       const response = await axios.get(`/api/surveyratingsettings/findSessionSettings?sessionId=${currentSessionId}`);
    //       const result = response.data;
    //       console.log("========result=============", result)
    //       // if (result.success) {
    //       //   setDomainSummary(result.data);
    //       // }
    //     } catch (error) {
    //       console.error('Error fetching executive summary:', error);
    //     }
    // }

    return (
        <>
        <UserLayout>        
            <Grid container spacing={6}>            
                <Grid item xs={12} md={2}>
                    <h1 className='mb-3'>Reports</h1>
                </Grid> 
                { sessions && sessions.length > 0 ?
                    <>
                        <Grid item xs={12} md={3}>
                            <TextField fullWidth
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                              }}
                              sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                              }}
                            select size='small' label='Session' id='sessionId' 
                            value={currentSessionId} 
                            onChange={(e) => handleSessionChange(Number(e.target.value))} >
                                { sessions.length > 0 ? 
                                    sessions.map(session => (
                                        <MenuItem key={session.id} value={session.id} >
                                                {session.name}
                                        </MenuItem>
                                    ))
                                : 
                                    <MenuItem value=''>No session available </MenuItem>
                                }                         
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            { authUser.role === 'Admin' ? 
                                <TextField fullWidth
                                ListboxProps={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                  }}
                                  sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                  }}
                                select size='small' label='User' id='userId' 
                                value={currentUserId} 
                                onChange={(e) => handleUserChange(Number(e.target.value))} >
                                    { selfEvaluations && selfEvaluations.length > 0 ? 
                                        selfEvaluations.map(selfEvaluation => (
                                            <MenuItem key={"user_"+selfEvaluation.id} value={selfEvaluation.user.id} >
                                                    {selfEvaluation.user.name}
                                            </MenuItem>
                                        ))
                                    : 
                                        <MenuItem value=''>No user available </MenuItem>
                                    }
                                </TextField>
                            : ''}
                        </Grid> 
                        {currentUserId > 0 ? 
                        <Grid item xs={12} md={3} className='text-end mt-1'>
                        {/* <PDFDownloadLink document={<ReportDownloadView />} fileName={"Rajiv.pdf"}>
                            {({ blob, url, loading, error }) =>
                                loading ? '' : 
                                 <Button size='small' variant='contained'>
                                    Download Report
                                </Button> 
                            }
                        </PDFDownloadLink>  */}
                        <Button size='small' variant='contained' onClick={() => handleDownload()}>
                            Download Report
                        </Button> 
                           
                        </Grid> : ""}
                    </>
                :''}
                <Grid item xs={12} md={4}>
                </Grid>      
            </Grid>
              
            { sessions && sessions.length > 0 ?
                currentUserId > 0 ?  
                <Grid container spacing={6}>            
                    <Grid item xs={12} md={12}>
                        <CardNavigationCenter 
                            isLoading={isLoading} 
                            executiveSummary={executiveSummary}
                            domainSummary={domainSummary}
                            highLowScore={highLowScore}
                            positiveNegativeGap={positiveNegativeGap}
                            detailedResults={detailedResults}
                            strongestCaptureChart={strongestCaptureChart}
                            weakestCaptureChart={weakestCaptureChart}
                            domainCaptureChart={domainCaptureChart}
                        />
                    </Grid>   
                </Grid>                 
                    
                : 
                    <Grid container spacing={6} justifyContent="center">            
                        <Grid item xs={12} md={6}>
                            <Grid sx={{ padding: 5}}><h6 className='mt-5 fw-normal'>Select session and its user to see the report.</h6></Grid>
                        </Grid>       
                    </Grid>
                :
                    <Grid container spacing={6} justifyContent="center">            
                        <Grid item xs={12} md={6}>
                            <Grid sx={{ padding: 5 }}><h6 className='mt-5 fw-normal'>No report available.</h6></Grid>
                        </Grid>       
                    </Grid>      
            }
            {/* <Pdf ref={componentRef}/> */}
        </UserLayout>
        </>
    )
}