import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';

const PieComponent = ({chartData, domainCaptureChart, index}) => {
  const series = [(chartData.self * 100)/7, (chartData.other * 100)/7, (chartData.average * 100)/7];
  const labels = ['Self', 'You', 'Company'];
  const chartRef = useRef(null); // Create a ref for the chart instance

  const options = {
    chart: {
      background: '#f4f5fa',
      height: 300,
      type: 'radialBar',
      toolbar: {
        show: false
      },
      events: {
        mounted: () => {
          // Ensure that the chart is fully mounted before capturing
          if (chartRef.current && chartRef.current.chart) {
            const chartInstance = chartRef.current.chart; 
            domainCaptureChart(chartInstance, index); // Call the parent's onCapture function to capture the image
          }
        },
      },
    },
    plotOptions: {
      radialBar: {
        track: {
          background: '#fff',
          rounded: true,
          strokeWidth: '100%',
        },
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '25%',
          background: 'transparent',
          // image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          }
        },
        barLabels: {
          enabled: true,
          useSeriesColors: true,
          fontSize: '13px',
          offsetX: -8, // Adjust this value
          offsetY: 0,
          // style: {
          //   colors: ['#ff349b', '#2f669c', '#ffbf05']
          // },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + ((opts.w.globals.series[opts.seriesIndex]*7)/100).toFixed(1)
          },
        },
        radius: '70%',
        barWidth: '20%',
        stroke: {
          lineCap: "round",
          width: 10
        },
        offsetX: 0,
      },
      
    },
    
    // colors: ['#ff349b', '#2f669c', '#ffbf05'],
    colors: ['#ff349b', 'rgba(85, 153, 204, 0.7)', 'rgba(255, 191, 50, 0.7)'],
    labels: labels,
    // title: {
    //   text: chartData.domain,
    //   align: 'center',
    //   margin: 0,
    //   offsetX: 0,
    //   offsetY: 0,
    //   floating: false,
    //   style: {
    //     fontSize:  '14px',
    //     fontWeight:  'bold',
    //     color:  '#263238',
    //   },
    // },
    // legend: {
    //   show: true,
    //   floating: true,
    //   fontSize: '12px',
    //   fontWeight: 600,
    //   position: 'left',
    //   offsetX: 0,
    //   offsetY: 0,
    //   labels: {
    //     useSeriesColors: true,
    //   },
    //   markers: {
    //     width: 4,
    //     height: 4,
    //     radius: 0,
    //     strokeColor: '#000',
    //     fillColors: undefined,
    //     strokeWidth: 0,
    //     shape: "circle",
    //     offsetX: 0,
    //     offsetY: 0,
    //   },
    //   formatter: function (seriesName, opts) {
    //     return seriesName + ":  " + ((opts.w.globals.series[opts.seriesIndex]*7)/100).toFixed(1)
    //   },
    //   itemMargin: {
    //     vertical: 0,
    //     horizontal: 5
    //   }
    // },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };

  useEffect(() => {
    if (chartRef.current) {
      // Force a redraw to ensure bars are visible for radial bar chart
      chartRef.current.chart.updateOptions(options);
      
    }
  }, [options]);

  return (
    <div className="custom-angle-circle-chart" >
      <Chart options={options} series={series} type="radialBar" height={220} ref={chartRef}/>
    </div>
  );
};

export default PieComponent;
