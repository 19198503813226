import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default function Features() {
    return (
        <>
            <Header/>
            <section class="bg_cover">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="page-banner-cont">
                                <h2 className="text-white">Features</h2>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            Features
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7 mb-5 mb-md-0">
                            <p className="h6 text-theme text-uppercase">Core Features</p>
                            <h2 className="mb-3">Powerful Tools for Growth</h2>
                            <p className="text-muted fs-14 mb-3">Discover the core features that make PeopleAssay the ultimate feedback platform. From customizable templates to advanced analytics, we've got everything you need to drive growth and foster a culture of continuous improvement.</p>
                        </div>
                        <div className="col-md-5">
                            <div className="img-gradient img-gradient-right"><img className="img-fluid shadow rounded" src="./images/core_features.jpg" alt=""/></div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="img-gradient img-gradient-right"><img className="img-fluid shadow rounded" src="./images/advanced_capabilities.jpg" alt=""/></div>
                        </div>
                        <div className="col-md-7 mb-md-0">
                            <p className="h6 text-theme text-uppercase">Advanced Capabilities</p>
                            <h2 className="mb-3">Take Your Feedback Process to the Next Level</h2>
                            <p className="text-muted fs-14">Unlock the full potential of PeopleAssay with our advanced capabilities. From integration options to AI-driven insights, we're pushing the boundaries of what's possible with feedback.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>            
        </>
        
    )     
}